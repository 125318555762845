<template>
  <CrudCardTable
    title="Items"
    :items="instances"
    :headers="headers"
    :button-to="{name: 'App-MenuCreator-Items-New'}"
  >
    <template #item.name="{ item }">
      <router-link :to="{ name: 'App-MenuCreator-Items-id', params: { id: item.id } }">
        {{ item.name }}
      </router-link>
    </template>
    <template #item.itemCustomizers="{ item }">
      <v-chip-group>
        <v-chip
          v-for="itemCustomizer in item.itemCustomizers"
          :key="itemCustomizer.$id"
        >
          {{ itemCustomizer.customizer ? itemCustomizer.customizer.name : "" }}
        </v-chip>
      </v-chip-group>
    </template>
  </CrudCardTable>
</template>

<script>
import { mapGetters } from 'vuex'
import CrudCardTable from '@/components/CrudCardTable'

export default {
  name: 'ItemList',
  components: { CrudCardTable },
  data () {
    return {
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Price', value: 'price' },
        { text: 'vat', value: 'vat' },
        { text: 'Customizers', value: 'itemCustomizers' }
      ]
    }
  },
  computed: {
    ...mapGetters('app', ['items']),
    instances () {
      return this.items.all()
    }
  }
}
</script>
